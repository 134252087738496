import React from "react";
import { Link } from "react-router-dom";

const GameRules = () => {
  return (
    <div className="background-white">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <h2 className="mb-3">Ocee Game Rules and Regulations</h2>
            <div>
              <p className="f-16 fw-bold">1. General Conduct:</p>
              <ul>
                <li>Users must be respectful and courteous to each other.</li>
                <li>
                  Hate speech, harassment, or any form of abusive behavior will
                  not be tolerated.
                </li>
                <li>
                  Impersonation of staff or other users is strictly prohibited.
                </li>
              </ul>
            </div>
            <div>
              <p className="f-16 fw-bold">2. Age Requirement:</p>
              <ul>
                <li>
                  Users must be at least 5 years old to register and use the
                  website.
                </li>
              </ul>
            </div>
            <div>
              <p className="f-16 fw-bold">3. User Accounts:</p>
              <ul>
                <li>
                  Users are responsible for maintaining the security of their
                  accounts.
                </li>
                <li>Sharing of account information is not allowed.</li>
                <li>
                  Users should report any suspicious activities or unauthorized
                  access to their accounts immediately.
                </li>
              </ul>
            </div>

            <div>
              <p className="f-16 fw-bold">4. Content Guidelines:</p>
              <ul>
                <li>
                  Users are prohibited from uploading, sharing, or promoting
                  content that is:
                </li>
                <ul>
                  <li>Offensive, harmful, or obscene.</li>
                  <li>Infringing on intellectual property rights.</li>
                  <li>Violent or encourages violence.</li>
                </ul>
                <li>
                  Users are responsible for the content they upload, and
                  OCEEGame.com reserves the right to remove any content deemed
                  inappropriate.
                </li>
              </ul>
            </div>
            <div>
              <p className="f-16 fw-bold">5. Cheating and Exploits:</p>
              <ul>
                <li>
                  Cheating, hacking, or using exploits to gain an unfair
                  advantage is strictly prohibited.
                </li>
                <li>
                  Users found violating this rule may face account suspension or
                  permanent ban.
                </li>
              </ul>
            </div>
            <div>
              <p className="f-16 fw-bold">6. Privacy:</p>
              <ul>
                <li>Users are encouraged to respect the privacy of others.</li>
                <li>
                  Personal information should not be shared without consent.
                </li>
                <li>
                  OCEEGame.com collects and uses personal information in
                  accordance with its{" "}
                  <Link to="/privacy-policy" className="text-danger">
                    Privacy Policy
                  </Link>
                  .
                </li>
              </ul>
            </div>
            <div>
              <p className="f-16 fw-bold">7. Reporting:</p>
              <ul>
                <li>
                  Users are encouraged to report any violations of the rules
                  promptly.
                </li>
                <li>
                  False reporting or misuse of the reporting system may result
                  in disciplinary action.
                </li>
              </ul>
            </div>
            <div>
              <p className="f-16 fw-bold">8. Moderation and Enforcement:</p>
              <ul>
                <li>
                  OceeGame.com reserves the right to enforce these rules at its
                  discretion.
                </li>
                <li>
                  Moderators may take appropriate action, including warnings,
                  temporary suspensions, or permanent bans.
                </li>
              </ul>
            </div>
            <div>
              <p className="f-16 fw-bold">9. Updates to Rules:</p>
              <ul>
                <li>OceeGame.com may update these rules at any time.</li>
                <li>
                  Users are responsible for staying informed about rule changes.
                </li>
              </ul>
            </div>
            <div>
              <p className="f-16 fw-bold">10. Legal Compliance:</p>
              <ul>
                <li>
                  Users must comply with all applicable laws and regulations.
                </li>
                <li>
                  OceeGame.com is not responsible for user actions that violate
                  local, national, or international laws.
                </li>
              </ul>
              <p className="f-16 fw-normal">
                By using OCEEGame.com, users agree to abide by these rules and
                regulations. Failure to comply may result in disciplinary
                action, including account suspension or permanent ban.
              </p>
              <p className="f-16 fw-normal">Thank You.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameRules;
