import "./App.css";
// import { BrowserRouter } from "react-router-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Pages from "./Pages/index";
import Dashboard from "./Pages/Dashboard";
import GameFrame from "./Pages/GameCanvas";
import ContactUs from "./Pages/ContactUs";
import GameRules from "./Pages/GameRules";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Layout from "./Layout";
import { useEffect, useState } from "react";
import ReactGA from "react-ga";
import Blog from "./Pages/Blog";

// Create a theme
const theme = createTheme();
// const TRACKING_ID = "G-1313ERM784"; //G-1313ERM784 //G-RB674RL80H

ReactGA.initialize("G-LP3HX8800F");

function App() {
  const [selectedGame, setSelectedGame] = useState(null);
  const [selectedGameName, setSelectedGameName] = useState(null);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    const gameUrl = localStorage.getItem("selectedGame");
    const gameUrlName = localStorage.getItem("selectedGameName");
    setSelectedGame(gameUrl);
    setSelectedGameName(gameUrlName);
    console.log("storedGame[3]", gameUrl, selectedGame);
  }, []);

  console.log("storedGame[2]", selectedGame, selectedGameName);
  console.log("storedGame[5]");

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        {/* <Pages /> */}
        <Layout>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path={`/games`} element={<GameFrame />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/game-rules/" element={<GameRules />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
