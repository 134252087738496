import React from "react";
import { Link } from "react-router-dom";

const ContactUs = () => {
  return (
    <div className="background-white">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <h1>Contact Us</h1>
            <div className="text-center">
              <p className="f-16 fw-normal">
                Welcome to OCEE Game! We’re delighted that you want to get in
                touch with us.
              </p>
              <p className="f-16 fw-normal">
                Whether you have a question, a suggestion, or just want to say
                hello, we’d love to hear from you.
              </p>
            </div>
            <div className="text-center">
              <h2 className="mt-4 mb-3">How to Reach Us</h2>
              <h3>Customer Support:</h3>
              <p className="f-16 fw-normal">
                Have a question about a game or need assistance? Our customer
                support team is here to help. You can reach them via email at{" "}
                <span className="text-danger fw-bold">oceegames@gmail.com</span>{" "}
                for a prompt response.
              </p>
            </div>
          </div>
          <hr />
          <div className="text-center">
            <h3>General Inquiries:</h3>
            <p className="f-16 fw-normal">
              For general inquiries, partnerships, or business-related matters,
              you can contact us at{" "}
              <span className="text-danger fw-bold">oceegames@gmail.com</span>
            </p>
            <p className="f-16 fw-normal">
              We appreciate your interest and will get back to you as soon as
              possible.
            </p>
          </div>
          <div className="text-center">
            <h2 className="mt-4 mb-3">Feedback:</h2>
            <p className="f-16 fw-normal">
              We value your feedback. Let us know what you love about OCEE Game
              or how we can make your gaming experience even better. Send your
              thoughts to{" "}
              <span className="text-danger fw-bold">oceegames@gmail.com</span>.
            </p>
            <p className="f-16 fw-normal">
              Thank you for choosing{" "}
              <Link to="/" className="text-danger fw-normal">
                Ocee Game
              </Link>{" "}
              We look forward to hearing from you!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
