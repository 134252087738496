import React, { useEffect, useState } from "react";
import Logo from "../../Assets/icon/logo.svg";
import { Link, useLocation } from "react-router-dom";
import "../../Assets/style/nav.css";

const Header = () => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const gameCanvas = location.pathname === "/game-canvas";
  // State to manage the visibility of the mobile menu
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Function to toggle the mobile menu
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prevState) => !prevState);
  };

  // Close the menu if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isMobileMenuOpen && !event.target.closest(".nav-container")) {
        setIsMobileMenuOpen(false);
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [isMobileMenuOpen]);

  return (
    <>
      <header>
        <div className="nav-container">
          <input
            type="checkbox"
            name="check"
            id="check"
            checked={isMobileMenuOpen}
            onChange={toggleMobileMenu}
            className="check-input"
          />
          <label htmlFor="check" className="close-icon"></label>{" "}
          {/* Label for close icon */}
          <div className="logo-container">
            <Link className="navbar-brand" to="/">
              <img src={Logo} height={"40px"} alt="logo not founded" />
            </Link>
          </div>
          <div className="nav-btn" id="menu-container">
            <div className="nav-links">
              <ul>
                <li className="nav-link" style={{ "--i": ".3s" }}>
                  <Link className="nav-link" to="/" onClick={toggleMobileMenu}>
                    Home
                  </Link>
                </li>
                <li className="nav-link" style={{ "--i": ".4s" }}>
                  <Link
                    className="nav-link"
                    to="/game-rules"
                    onClick={toggleMobileMenu}
                  >
                    Game Rules
                  </Link>
                </li>
                <li className="nav-link" style={{ "--i": " .5s" }}>
                  <Link
                    className="nav-link"
                    to="/blog"
                    onClick={toggleMobileMenu}
                  >
                    Blog
                  </Link>
                </li>
                <li className="nav-link" style={{ "--i": " .6s" }}>
                  <Link
                    className="nav-link"
                    to="/contact-us"
                    onClick={toggleMobileMenu}
                  >
                    Contact Us
                  </Link>
                </li>
                <li className="nav-link" style={{ "--i": " .7s" }}>
                  <Link
                    className="nav-link"
                    to="/privacy-policy"
                    onClick={toggleMobileMenu}
                  >
                    Privacy policy
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="hamburger-menu-container">
            <div className="hamburger-menu">
              <div></div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
